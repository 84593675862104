<template>
    <BudgetNewStart/>
    <LoadRecentBudget v-if="store.state.NewBudgetStatus === ''"  />
    <BudNumPlus v-if="store.state.NewBudgetStatus === ''"  />
    <DeleteBudgetList v-if="store.state.NewBudgetStatus === ''" />
    <W4C_BasicInput @success="reloadWindow" v-if="store.state.NewBudgetStatus === ''" />
    <v-menu offset-y style="margin-right: 4px;" v-if="store.state.NewBudgetStatus === ''" >
      <template v-slot:activator="{ props }">
        <v-btn class="jogunSelect" v-bind="props">다운로드</v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-btn class="jogunSelect" style="color: #333">W4C임직원보수일람표</v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn class="jogunSelect" style="color: #333">PDF 예산</v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn class="jogunSelect" style="color: #333">W4C 예산</v-btn>
        </v-list-item>
        <v-list-item>
          <BudgetExcel/>
        </v-list-item>
      </v-list>
    </v-menu>
    <BudgetHowDid/>
</template>
<script setup lang="ts">
import store from "@/store";
import DeleteBudgetList from '@/components/budget/DeleteBudgetList.vue';
import BudNumPlus from '@/components/budget/BudNumPlus.vue';
import W4C_BasicInput from "@/components/budget/W4C_BasicInput.vue";
import BudgetHowDid from "@/components/budget/BudgetHowDid.vue";
import BudgetExcel from '@/components/budget/BudgetExcel.vue';
import LoadRecentBudget from "@/components/budget/LoadRecentBudget.vue";
import BudgetNewStart from '@/components/budget/BudgetNewStart.vue';

const reloadWindow = () => {
  window.location.reload();
}
</script>