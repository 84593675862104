<template>
  <v-dialog v-model="dialog" max-width="300">
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn v-bind="activatorProps" @click="openDialog" class="jogunSelect" style="color: #333">W4C기관정보</v-btn>
    </template>
    <v-card class="w4cBasicInputCard fontNotoSans400 pr-4 pl-4 pb-0 pt-4">
    <h3 class="tac">w4c기관정보</h3>
    <p class="tac">{{ store.state.mainCompBizGubun }}</p>
      <v-text-field 
        v-model="form.name"
        label="W4C 사업명" 
        flat 
        hide-details="auto" 
        variant="outlined"
        density="compact"
        maxlength="30"/>
      <v-text-field 
        v-model="form.code"
        label="W4C 사업코드" 
        flat 
        hide-details="auto" 
        variant="outlined"
        density="compact"
        maxlength="5"/>
      <v-text-field 
        v-model="form.kind"
        label="W4C 급여유형" 
        flat 
        hide-details="auto" 
        variant="outlined"
        density="compact"
        maxlength="30"/>
      <v-text-field 
        v-model="form.capa"
        label="현재 정원" 
        flat 
        hide-details="auto" 
        variant="outlined"
        density="compact"
        maxlength="5"/>
      <template v-slot:actions>
        <v-btn style="height: 50px; width: 50%" @click="dialog = false" size="big">
          닫기
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn style="height: 50px; width: 50%" @click="saveW4CBasicInfo()" size="big">
          저장
        </v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, reactive } from "vue";
import store from '@/store';
import axios from 'axios';
// eslint-disable-next-line no-undef
const emit = defineEmits(['success']);
const dialog = ref(false);
const mainComp = ref(null) as any;
const mainCompBizGubun = ref(null) as any;
const mainYear = ref(null) as any;
const budNum = ref(null) as any;

const form = reactive({
  idx : 0,
  name : '',
  code : '',
  kind : '',
  capa : '',
  bud_class: ''
})
const getW4CBasicInfo = async () => {
  try {
    const response = await axios.get(`/api/getW4CBasicInfo/${mainComp.value}/${mainCompBizGubun.value}/${mainYear.value}/${budNum.value}`);
    const responseData = response.data;

    if (responseData) {
      form.name = responseData?.w4c사업명;
      form.code = responseData?.w4c사업코드;
      form.kind = responseData?.w4c_pay_gubun;
      form.capa = responseData?.personnel;
      form.idx = responseData?.idx;
      form.bud_class = responseData?.bud_class;
      return responseData; // 가져온 데이터를 반환
    } else {
        console.error('에러가 발생했습니다.', responseData);
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};
const saveW4CBasicInfo = async () => {
  try {
    const response = await axios.post(`/api/saveW4CBasicInfo/${mainComp.value}/${mainCompBizGubun.value}/${mainYear.value}`, {form : form});

    const responseData = response.data;
    if (responseData.success === true) {
      emit('success');
      alert('저장이 완료되었습니다.');
      dialog.value = false;
    } else {
      console.error('에러가 발생했습니다.', responseData);
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};
const openDialog = async() => {
  // dialog.value = true;
  mainComp.value = store.state.mainComp;
  mainCompBizGubun.value = store.state.mainCompBizGubun;
  mainYear.value = store.state.mainYear;
  budNum.value = store.state.mainBudNum;
  await getW4CBasicInfo();
}


</script>
<style>
.w4cBasicInputCard .v-text-field{
  margin-top: 20px;
}
</style>