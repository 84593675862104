<template>
  <v-btn v-if="!loadingStatus && (store.state.lastBudClass=== store.state.mainBudNum)"  @click="budNumPlus()" class="jogunSelect" style="color: #333;">추가예산작성</v-btn>
  <div v-if="loadingStatus" class="loading-spinner"></div>

</template>
<script setup lang="ts">
import store from '@/store';
import axios from 'axios';
import { ref } from 'vue';
const loadingStatus = ref(false);
const budNumPlus = async () => {
  if(loadingStatus.value){
      alert('이미 동작 중입니다.');
      return;
    }
  try{
    loadingStatus.value = true;
    const isConfirmed = window.confirm(`${store.state.mainBudNum}을 복사하여 ${formatBudNum(store.state.mainBudNum)} 예산에 붙여넣습니다. \n인원이 추가된 경우 추가예산에서 수정하시기 바랍니다. 
    \n새로 ${formatBudNum(store.state.mainBudNum)}을 생성하시겠습니까?`);
    if (isConfirmed) {
      const response = await axios.post(`/api/budNumPlus/${store.state.mainComp}/${store.state.mainCompBizGubun}/${store.state.mainYear}/${store.state.mainBudNum}/${formatBudNum(store.state.mainBudNum)}`);
      const result = response.data;
      if (result) {
        alert(`${formatBudNum(store.state.mainBudNum)} 생성이 완료되었습니다.`);
        window.location.reload();
        store.commit("setLastBudClass", formatBudNum(store.state.mainBudNum));
        store.commit('setMainBudNum', formatBudNum(store.state.mainBudNum));
      } else {
        alert(`${formatBudNum(store.state.mainBudNum)} 생성에 실패하였습니다.`);
      }
    } else {
      return;
    }
  }catch (error){
    console.error("errorMsg:", error);
  }finally{
    loadingStatus.value = false;
  }
}
const formatBudNum = (budNum : any) => {
  if (budNum === '본예산') {
    budNum = `1차 추경`;
  } else {
    // 문자열에서 가장 앞에 있는 숫자 추출
    const match = budNum.match(/(\d+)/); // 정규 표현식으로 숫자 추출
    if (match) {
      budNum = `${parseInt(match[0], 10)+1}차 추경`; // 추출한 숫자를 정수로 변환
    } else {
      // 숫자가 없을 경우에 대한 처리 (필요 시)
      budNum = null; // 또는 다른 기본값 설정
    }
  }
  return budNum;
}
</script>