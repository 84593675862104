<template>
  <v-dialog  v-model="dialog" max-width="900" >
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn color="red-lighten-2" style="margin-left: 4px; font-size: 14px;" v-bind="activatorProps">※설명</v-btn>
    </template>
    <v-card class="fontNotoSans400">
    <div style="float: right;">
      <v-icon class="closeIcon mt-1" @click="dialog = false" size="small">{{ mdiWindowClose }}</v-icon>
    </div>

      <v-card-text>
        <p class="mt-3 mb-3" style="font-size: 20px; font-weight: bold;"> * 기초설명 </p>
        <p style="font-size: 16px;"> - 기관변경 시 사업구분이 자동할당되고, 설정된 연도에 따라 가장 높은차수의 추경차수가 자동할당됩니다.</p>
        <p style="font-size: 16px;"> - 본예산 작성은 따로 존재하지 않습니다.</p>
        <p style="font-size: 16px;"> - 급여 저장 시 인건비 지출 비율이 부족하다면 알림이 출력됩니다.</p>
        <p style="font-size: 16px;"> (ex. 2025년 예산작성은 상단 바를 2025년도로 맞추고 적용버튼을 누르면 2025년 본예산작성 준비가 완료됩니다. )</p>
        <p style="font-size: 16px;"> - 예산정보는 항상 입력, 수정, 삭제가 가능합니다.</p>
        <p style="font-size: 16px;"> - w4c기관정보는 가장 최근에 저장한 연도, 추경차수를 기준으로 자동복사됩니다.</p>


        <p class="mt-10 mb-3" style="font-size: 20px; font-weight: bold;"> * 버튼설명 </p>
        <p style="font-size: 18px; font-weight: 500;"> 1. 예산조회 페이지 </p>
        <p style="font-size: 16px;"> - [산출기초] : 예산작성 페이지로 이동됩니다.</p>
        <p style="font-size: 16px;"> - [추가예산작성] : 다음차수 추경 생성, 자동으로 이전 예산이 복사됩니다.</p>
        <p style="font-size: 16px;"> - [예산삭제] : 현재 페이지의 예산이 삭제됩니다.</p>


        <p class="mt-3" style="font-size: 18px; font-weight: 500;"> 2. 산출기초 페이지 </p>
        <p style="font-size: 16px;"> - [예산조회] : 예산조회 페이지로 이동됩니다.</p>
        <p style="font-size: 16px;"> - [최근예산복사] : 가장 최근의 예산이 복사됩니다.</p>
        <p style="font-size: 16px;">  ( 25년 본예산 페이지에서 클릭 시 24년 마지막 추경차수 예산이 복사됩니다 )</p>
        

        <p class="mt-3" style="font-size: 18px; font-weight: 500;"> 3. 공통 </p>
        <p style="font-size: 16px;"> - 입/출/차/ 인건비비율 텍스트 색으로 적합한지 아닌지 확인할 수 있습니다.</p>
        <p style="font-size: 16px;"> - [W4C기관정보] : stepu세입예산 작성 시 기관정보 입력란과 같은 기능입니다.</p>
        <p style="font-size: 16px;"> - [다운로드] : 예산관련 파일 다운로드(미개발, 개발 예정)</p>


        <p class="mt-10 mb-3" style="font-size: 20px; font-weight: bold;"> * 기본기능 </p>
        <p style="font-size: 16px;"> - [W4C기관정보]의 '정원'은 예산작성 시 디폴트값의 기준으로 작용합니다. 꼭 확인해주세요.</p>
        <p style="font-size: 16px;"> - 세입의 자금원천은 자동으로 할당되어있습니다.</p>
        <p style="font-size: 16px;"> - '목' 더블클릭 시 내용란에 같은 글자가 복사됩니다.</p>
        <p style="font-size: 16px;"> (ex. '식재료비수입' 글자가 담긴 곳을 더블클릭해주세요. / 단, 내용란에 선택상자가 있는 경우는 제외.)</p>
        <p style="font-size: 16px;"> - 단가, 명(회), 개월 입력 시 금액과 예산액이 곧바로 계산되어 나타납니다.</p>
        <p style="font-size: 16px;"> - 모든 란에 값을 채워야 저장이 가능합니다.</p>
        <p style="font-size: 16px;"> - 저장을 누르면 화면 하단에 완료표시가 출력됩니다. 삭제(X) 클릭 시 확인알람이 출력되고, 확인을 누르면 삭제가 됩니다.</p>
        <p style="font-size: 16px;"> - +버튼을 누르면 같은 '목'에 대하여 추가 작성이 가능한 란이 생성됩니다.</p>
        <p style="font-size: 16px;"> - 저장,삭제 시 표 좌상단 입/출/차/ 인건비비율에 곧바로 반영됩니다.</p>


        <p class="mt-10 mb-3" style="font-size: 20px; font-weight: bold;"> * 상세기능 </p>
        <p style="font-size: 16px;"> - 급여(직접비,간접비) 생성,수정 or 삭제 시 퇴직금, 사회보험부담금이 자동으로 생성,수정 or 삭제됩니다.</p>
        <p style="font-size: 16px;"> - 생계비, 생계비(보조금),의료비는 세입의 식재료비,시군구,기타비급여와 연동되어 자동으로 생성, 수정 or 삭제됩니다.</p>
        <p class="mb-3" style="font-size: 16px;"> (작성한 정보는 곧바로 저장을 눌러주세요.)</p>
        <p style="font-size: 16px;"> - 퇴직금, 사회보험부담금은 개별로 조정이 가능합니다.</p>
        <p class="mb-3" style="font-size: 16px;"> - 대표자 여부 체크박스에 체크하면 고용보험,산재보험,퇴직금 계산을 하지 않습니다.</p>
        <p class="mb-3" style="font-size: 16px;"> - 본인부담금, 장기요양급여수입은 예산작성 초기에 모든 입력란에 디폴트값이 할당되어있습니다. 확인 후 [저장버튼]을 꼭 눌러주세요.</p>
       
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { mdiWindowClose } from '@mdi/js';
import { ref } from 'vue';
const dialog = ref(false);

</script>