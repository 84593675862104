<template>
  <v-btn @click="getBudgetExcel()" class="jogunSelect" style="float: right">EXCEL 예산</v-btn>
</template>
<script setup lang="ts">
import axios from "axios";
import store from "@/store";
const getBudgetExcel = async () => {
  try {
    if(store.state.mainComp){
      return;
    }
    const response = await axios.post(`/api/getBudgetExcel/
    ${formatCompId(store.state.mainComp)}/${store.state.mainCompBizGubun}/${store.state.mainYear}/${store.state.mainBudNum}`, 
    {
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${formatCompId(store.state.mainComp)}_${store.state.mainCompBizGubun}_${store.state.mainYear}년도_예산.xlsx`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error("Excel 파일 다운로드 중 에러 발생:", error);
  }
};
function formatCompId(str: any) {
  const regex = /\(([^)]+)\)/;
  const matches = str.match(regex);
  if (matches && matches.length > 1) {
    return matches[2];
  } else {
    return null; // 괄호 안의 문자열이 없는 경우
  }
}
</script>
