<template>
  <v-dialog  v-model="dialog" max-width="800" >
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn color="red-lighten-2" style="font-size: 14px;" v-bind="activatorProps">※설명</v-btn>
    </template>
    <v-card class="fontNotoSans400">
      <v-card-title class="inoutCtrlTitle pr-6 pl-6 pt-4">
        <b>※ 설명서</b>
        <p>※ 화면 최상단의 기관을 꼭 확인하시고 진행해주세요.</p>
        <v-icon class="closeIcon mt-1" @click="dialog = false" size="small">{{ mdiWindowClose }}</v-icon>
      </v-card-title>
      <v-card-text class="pb-8">
        <h4>1. 엑셀업로드</h4>
        <h4>→ 최상단 [시설], [사업구분] 선택 후 [엑셀업로드 파일선택] 으로 파일 업로드.</h4>
        <p style="font-size: 13px;"> - 업로드 시 데이터가 존재하는 구분, 연월의 기존 데이터는 삭제.</p>
        
        <h4 class="mt-2">2. (일괄)선택조정</h4>
        <h4>→ 표의 좌측 세로열에서 기능적용 대상 선택 후 표의 우측 최상단의 [자동],[선택조정], [선택삭제] 기능 제공.</h4>
        <p style="font-size: 13px;"> - 각 항목마다 존재하는 (조정하기) 클릭으로 활성화 시킨 뒤 작성.</p>
        <p style="font-size: 13px;"> - (X)는 수정 불가 항목.</p>
        <p style="font-size: 13px;"> - 기능적용 대상 선택 시 적요검색 우측에 선택금액을 합산하여 표시함.</p>
        <p style="font-size: 13px;"> - 상대계정 선택 시 계정별 남은 금액이 표시되고, 상대계정 선택 후 선택금액 합산의 우측에 선택한 계정의 잔여금액이 표시됨.</p>
        <p style="font-size: 13px;"> - 좌상단 [구분] 조건에 맞는 항목의 데이터가 출력.</p>
        <p style="font-size: 13px;"> - 계정변경 시 자금원천란의 자동 활성화 및 해당하는 자금원천이 자동으로 선택됨.</p>
        <p style="font-size: 13px;"> - [자동]은 남은 계정금액에 따른 상대계정이 부여되고 생계비↔식재료비/생계비보조↔시군구보조/의료비↔기타비급여는 고정매칭.</p>
        <p style="font-size: 13px;"> - 계정 잔여금 새로고침(stepu에서 조정한 현금출납부는 잔여금 계산에 적용되지 않으므로 새로고침을 눌러주세요.)</p>
        <div v-if="isLoading" class="loading-spinner"></div>
        <v-btn v-if="!isLoading" @click="getInoutDetailCalc" >
          계정에 문제가 있다면 클릭하고 확인해주세요.
        </v-btn>

        <h4 class="mt-2">3. 개별조정</h4>
        <h4>→ 표의 각 가로열 우측의 [조정],[삭제] 버튼으로 별도의 기능적용 선택없이 개별 대상에 대한 기능 제공.</h4>

        <h4 class="mt-2">4. 표의 좌측 상단에서 조건검색 가능.</h4>
        <p style="font-size: 13px;"> - 세출/세입, 조정/미조정 조건 변경 시 체크된 항목은 모두 체크가 해제됨.</p>

        <h4 class="mt-2">5. 기존의 [재무회계 보고서] 및 [W4C임금총액] 은 우상단 [보고서] 로 진입 가능.</h4>
        
        <h4 class="mt-2">6. [보고서] → [현금출납누적현황표] 는 관항목 기준으로 현금출납 내역 확인 가능, 날짜 조건 검색 불가능.</h4>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import store from '@/store';
import { mdiWindowClose } from '@mdi/js';
import axios from 'axios';
import { ref } from 'vue';
const isLoading = ref(false);
const dialog = ref(false);
const getInoutDetailCalc = async () => {
  try {
    isLoading.value = true;
    const response = await axios.get(
      `/api/getInoutDetailCalc/${(store.state.mainStartDate)}/${(store.state.mainEndDate)}/${store.state.mainComp}/${store.state.mainCompBizGubun}`);
    const responseData = response.data;

    if (Array.isArray(responseData)) {
      store.commit('setInoutDetailList', responseData);
      return responseData;
    } else {
      console.error("에러가 발생했습니다.", responseData);
    }
  } catch (error) {
    console.error("errorMsg:", error);
  } finally {
    isLoading.value = false;
  }
};
</script>