<template>
 
   <div v-if="loadingStatus" class="loading-spinner"></div>
 <v-btn v-if="store.state.NewBudgetStatus === 'not-value'"  class="jogunSelect" @click="getOneMemBudClass()" style="color: #333">본예산 작성</v-btn>
</template>
<script setup lang="ts">
import router from '@/router';
import store from '@/store';
import axios from 'axios';
import { ref } from 'vue';
const loadingStatus = ref(false);
const getOneMemBudClass = async () => {
  if(loadingStatus.value){
      alert('이미 동작 중입니다.');
      return;
    }
  try {
    loadingStatus.value = true;
    const response = await axios.post(
    `/api/makeOneMemBudClass/${store.state.mainComp}/${store.state.mainCompBizGubun}/${store.state.mainYear}`
  );
    const responseData = response.data;
    router.push('/MngBudgetDetailList').then(() => {
        window.location.reload(); // 페이지 새로고침
      });
    return responseData;
  } catch (error) {
    console.error("errorMsg:", error);
}finally{
    loadingStatus.value = false;
  }
};

</script>