<template>
     <table v-if="budSumList" style="float: left; margin-left: 20px; font-size: 18px; border-collapse: collapse;">
      <tr>
      <td style="padding: 0 48px 0 0; text-align: left;"><h2>예산조회</h2></td>
      <td style="padding: 0; text-align: left">세입</td>
      <td style="padding: 0 10px; text-align: right; border-right: 1px solid #333">
        ₩ {{ budSumList.in_예산총액_총합  || 0}}
      </td>

      <td style="padding: 0 0 0 10px; text-align: left">세출</td>
      <td style="padding: 0 10px; text-align: right; border-right: 1px solid #333">
        ₩ {{ budSumList.out_예산총액_총합 || 0}}
      </td>

      <td style="padding: 0 0 0 10px; text-align: left">차액</td>
      <td
        :style="{
          padding: '0 10px',
          textAlign: 'right',
          borderRight: '1px solid #333',
          color:
            budSumList.in_total_budget_money - budSumList.out_total_budget_money === 0
              ? 'green'
              : 'red',
        }"
      >
        ₩
        {{
          addComma(budSumList.in_total_budget_money - budSumList.out_total_budget_money) || 0
        }}
      </td>
      <td style="padding: 0 0 0 20px; text-align: left">인건비 지출 비율</td>
      <td>
        <span :style="{
          padding: '5px',
          textAlign: 'right',
          color:
            laborPercent > percent ? 'green' : laborPercent < percent ? 'red' : 'green',
        }">{{ laborPercent  || 0}}%</span>
        <span v-if="laborPercent > percent" style="font-size:16px;">&gt; {{ percent }}%</span>
        <span v-else-if="laborPercent < percent" style="font-size:19px;">&lt; {{ percent }}%</span>
        <span v-else style="font-size:16px;">= {{ percent }}%</span>
      </td>
    </tr>
    </table>

   <div style="padding: 0 16px 16px 16px; text-align: right;">
      <v-btn v-if="store.state.NewBudgetStatus === ''"   @click="goBudgetDetailList" class="jogunSelect">산출기초</v-btn>
      <BudgetBtns/>
    </div>
    <div v-if="isLoading" class="loading-spinner"></div>

  <v-card class="table-container" v-else>
   
    <table class="budListTable" >
      <thead>
        <tr>
          <th>구분</th>
          <th>관</th>
          <th>항</th>
          <th>목</th>
          <th>보조금</th>
          <th>자부담</th>
          <th>후원금</th>
          <th>수익사업</th>
          <th>예산총액</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="mainGubun === '전체' || mainGubun === '세입'">
          <tr
            class="budListTr"
            v-for="(item, index) in budInListWithRowspan"
            :key="index"
          >
            <!-- 구분 -->
            <td v-if="item.rowspan.구분" :rowspan="item.rowspan.구분" class="tac">
              {{ item.구분 }}
            </td>
            <!-- 관 -->
            <td v-if="item.rowspan.관" :rowspan="item.rowspan.관" class="tac">
              {{ item.관 }}
              <p v-if="filteredLnameTotal(item)">
                총 {{ ac(filteredLnameTotal(item).total) }} 원
              </p>
            </td>
            <!-- 항 -->
            <td v-if="item.rowspan.항" :rowspan="item.rowspan.항" class="tac">
              {{ item.항 }}
              <p v-if="filteredMnameTotal(item)">
                총 {{ ac(filteredMnameTotal(item).total) }} 원
              </p>
            </td>
            <!-- 나머지 항목들은 그대로 출력 -->
            <td class="tac inMok">{{ item.목 }}</td>
            <td class="tar">{{ item.보조금 }}</td>
            <td class="tar">{{ item.자부담 }}</td>
            <td class="tar">{{ item.후원금 }}</td>
            <td class="tar">{{ item.수익사업 }}</td>
            <td class="tar">{{ item.예산총액 }}</td>
          </tr>
          <tr class="budSumListTr" v-if="budSumList">
            <td style="font-weight: bold;" class="tac" colspan="4">합계</td>
            <td style="font-weight: bold;" class="tar">{{ budSumList.in_보조금_총합 || "" }}</td>
            <td style="font-weight: bold;" class="tar">{{ budSumList.in_자부담_총합 || "" }}</td>
            <td style="font-weight: bold;" class="tar">{{ budSumList.in_후원금_총합 || "" }}</td>
            <td style="font-weight: bold;" class="tar">{{ budSumList.in_수익사업_총합 || "" }}</td>
            <td style="font-weight: bold;" class="tar">{{ budSumList.in_예산총액_총합 || "" }}</td>
          </tr>
        </template>
        <template v-if="mainGubun === '전체' || mainGubun === '세출'">
          <tr
            class="budListTr"
            v-for="(item, index) in budOutListWithRowspan"
            :key="index"
          >
            <!-- 구분 -->
            <td v-if="item.rowspan.구분" :rowspan="item.rowspan.구분" class="tac">
              {{ item.구분 }}
            </td>
            <!-- 관 -->
            <td v-if="item.rowspan.관" :rowspan="item.rowspan.관" class="tac">
              {{ item.관 }}
              <p v-if="filteredLnameTotal(item)">
                총 {{ ac(filteredLnameTotal(item).total) }} 원
              </p>
            </td>
            <!-- 항 -->
            <td v-if="item.rowspan.항" :rowspan="item.rowspan.항" class="tac">
              {{ item.항 }}
              <p v-if="filteredMnameTotal(item)">
                총 {{ ac(filteredMnameTotal(item).total) }} 원
              </p>
            </td>
            <!-- 나머지 항목들은 그대로 출력 -->
            <td class="tac outMok">{{ item.목 }}</td>
            <td class="tar">{{ item.보조금 }}</td>
            <td class="tar">{{ item.자부담 }}</td>
            <td class="tar">{{ item.후원금 }}</td>
            <td class="tar">{{ item.수익사업 }}</td>
            <td class="tar">{{ item.예산총액 }}</td>
          </tr>
          <tr class="budSumListTr" v-if="budSumList">
            <td style="font-weight: bold;" class="tac" colspan="4">합계</td>
            <td style="font-weight: bold;" class="tar">{{ budSumList.out_보조금_총합 || "" }}</td>
            <td style="font-weight: bold;" class="tar">{{ budSumList.out_자부담_총합 || "" }}</td>
            <td style="font-weight: bold;" class="tar">{{ budSumList.out_후원금_총합 || "" }}</td>
            <td style="font-weight: bold;" class="tar">{{ budSumList.out_수익사업_총합 || "" }}</td>
            <td style="font-weight: bold;" class="tar">{{ budSumList.out_예산총액_총합 || "" }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </v-card>
</template>
<script setup lang="ts">
import BudgetBtns from "@/components/budget/BudgetBtns.vue";

import store from "@/store";
import { computed, onMounted, ref, watch } from "vue";
import axios from "axios";
import router from '@/router';
const isLoading = ref(false);

const mainComp = ref(null) as any;
const mainCompBizGubun = ref(null) as any;
const mainYear = ref(null) as any;
const budNum = ref(null) as any;
const mainGubun = ref(null) as any;

const budList = ref([] as any[]);
const budOutList = ref([] as any[]);
const budInList = ref([] as any[]);
const budSumList = ref();

const goBudgetDetailList = () => {
  router.push('/MngBudgetDetailList');
}
const budInListWithRowspan = ref([] as any[]);
const budOutListWithRowspan = ref([] as any[]);
const calculateRowspan = (list: any[]) => {
  const updatedList = list.map((item: any) => ({
    ...item,
    rowspan: { 구분: 1, 관: 1, 항: 1, 목: 1 },
  }));
  let 구분Rowspan = 1,
    관Rowspan = 1,
    항Rowspan = 1;

  for (let i = 0; i < updatedList.length; i++) {
    const current = updatedList[i];
    const prev = updatedList[i - 1];
    if (prev && prev.구분 === current.구분) {
      구분Rowspan++;
      current.rowspan.구분 = 0;
    } else {
      if (i > 0) updatedList[i - 구분Rowspan].rowspan.구분 = 구분Rowspan;
      구분Rowspan = 1;
    }
    if (prev && prev.관 === current.관 && prev.구분 === current.구분) {
      관Rowspan++;
      current.rowspan.관 = 0;
    } else {
      if (i > 0) updatedList[i - 관Rowspan].rowspan.관 = 관Rowspan;
      관Rowspan = 1;
    }
    if (
      prev &&
      prev.항 === current.항 &&
      prev.관 === current.관 &&
      prev.구분 === current.구분
    ) {
      항Rowspan++;
      current.rowspan.항 = 0;
    } else {
      if (i > 0) updatedList[i - 항Rowspan].rowspan.항 = 항Rowspan;
      항Rowspan = 1;
    }
  }

  // 마지막 그룹에 대해 rowspan 설정
  if (updatedList.length > 0) {
    updatedList[updatedList.length - 구분Rowspan].rowspan.구분 = 구분Rowspan;
    updatedList[updatedList.length - 관Rowspan].rowspan.관 = 관Rowspan;
    updatedList[updatedList.length - 항Rowspan].rowspan.항 = 항Rowspan;
  }

  return updatedList;
};
const laborPercent = ref();
const percent = ref();
const mnameTotal = ref([] as any);
const lnameTotal = ref([] as any);

const getBudgetList = async () => {
  try {
    isLoading.value = true;
    console.log(mainYear.value,'mainYear.value')
    const response = await axios.get(
      `/api/getBudgetList/${mainComp.value}/${mainCompBizGubun.value}/${mainYear.value}/${budNum.value}`
    );
    const responseData = response?.data;
    budSumList.value = responseData?.sum;
    budList.value = responseData?.list;
    percent.value = responseData?.percent?.percentage;
    mnameTotal.value = responseData?.mnameTotal;
    lnameTotal.value = responseData?.lnameTotal;

    laborPercent.value = parseFloat((responseData.laborPercent.labor_out / responseData.laborPercent.labor_in * 100).toFixed(1)) || 0;
    if(laborPercent.value === Infinity){
      laborPercent.value = ''; // 값이 없으면 0으로 설정
    }
    budOutList.value = budList.value.filter(
      (item: { 구분: string }) => item.구분 === "세출"
    );
    budInList.value = budList.value.filter(
      (item: { 구분: string }) => item.구분 === "세입"
    );

    // budInList의 rowspan 계산
    budInListWithRowspan.value = calculateRowspan(budInList.value);
    budOutListWithRowspan.value = calculateRowspan(budOutList.value);
    return responseData;
  } catch (error) {
    console.error("errorMsg:", error);
  } finally{
    isLoading.value = false;
  }
};

watch(
  () => [
    store.state.mainComp,
    store.state.mainCompBizGubun,
    store.state.mainYear,
    store.state.mainBudNum,
    store.state.mainGubun,
  ],
  async ([newMainComp, newMainCompBizGubun, newMainYear, newBudNum, newMainGubun]) => {
    mainComp.value = newMainComp;
    mainCompBizGubun.value = newMainCompBizGubun;
    mainYear.value = newMainYear;
    budNum.value = newBudNum;
    mainGubun.value = newMainGubun
    await getBudgetList();
  }
);
onMounted(async () => {
  mainComp.value = store.state.mainComp;
  mainCompBizGubun.value = store.state.mainCompBizGubun;
  mainYear.value = store.state.mainYear;
  budNum.value = store.state.mainBudNum;
  mainGubun.value = store.state.mainGubun;
  await getBudgetList();
});

function addComma(number: number) {
  // 입력이 숫자인지 확인 후 숫자로 강제 변환
  const num = Number(number);
  if (isNaN(num)) return number; // 숫자가 아닌 경우 그대로 반환
  if (num === 0) return "0"; // 0이 입력된 경우 "-" 반환
  // 숫자인 경우 컴마를 추가한 문자열 반환
  return num.toLocaleString();
}
function ac(value: any) {
  let num = Number(value);
  if (isNaN(num)) return null;
  if (num === 0) return "";
  return num.toLocaleString();
}
const filteredMnameTotal = computed(() => {
  return (item: any) => {
    return mnameTotal.value.find(
      (total: any) =>
        total.lcode === item.lcode &&
        total.mcode === item.mcode &&
        total.구분 === item.구분
    );
  };
});
const filteredLnameTotal = computed(() => {
  return (item: any) => {
    return lnameTotal.value.find(
      (total: any) =>
        total.lcode === item.lcode &&
        total.구분 === item.구분
    );
  };
});
</script>
<style>
.table-container {
  max-height: calc(100vh - 148px); /* 원하는 최대 높이 설정 */
  overflow-y: auto; /* Y축 스크롤 활성화 */
  overflow-x: auto; /* Y축 스크롤 활성화 */
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border: 1px solid #ccc; /* 테이블 테두리 */
}

.budListTable {
  max-width: 100%;
  width: 100%;
  min-width: 1230px;
  height: 100%;
  font-size: 18px;
  border-collapse: collapse;
}
.budListTable th {
  position: sticky; /* 헤더 고정 */
  top: 0; /* 상단에 고정 */
  z-index: 10; /* 헤더가 다른 요소 위에 위치하도록 설정 */
  font-size: 15px;
  padding: 8px 5px;
  height: 100%;
  background-color: rgb(196, 208, 255);
  border-left: 1px solid #b4b4b4;
  border-right: 1px solid #b4b4b4;
  border-bottom: 1px solid #b4b4b4;
  color: #282828;
}
.budListTable td {
  border: 1px solid #b4b4b4;
  padding: 8px 5px;
  color: #282828;
}
.budListTable .budListTr {
  font-size: 13px;
}
.budSumListTr {
  background-color: rgb(235, 239, 255);
  color: #282828;
}
.budListTable .budSumListTr td {
  font-size: 14px;
  padding: 6px 5px;
  font-weight: 500;
}
.budListTable tbody .budListTr:hover {
  background-color: #ececec;
}
.budListTable thead tr th:nth-child(1) {
  width: 3%;
}
.budListTable thead tr th:nth-child(2) {
  width: 12.125%;
}
.budListTable thead tr th:nth-child(3) {
  width: 12.125%;
}
.budListTable thead tr th:nth-child(4) {
  width: 12.125%;
}
.budListTable thead tr th:nth-child(5) {
  width: 12.125%;
}
.budListTable thead tr th:nth-child(6) {
  width: 12.125%;
}
.budListTable thead tr th:nth-child(7) {
  width: 12.125%;
}
.budListTable thead tr th:nth-child(8) {
  width: 12.125%;
}
.budListTable thead tr th:nth-child(9) {
  width: 12.125%;
}
</style>
